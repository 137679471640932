.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .cls-1,
.cls-19 {
  fill: #fff;
}

.cls-2 {
  fill: #b2ddc8;
}
.cls-2 {
  fill: #b2ddc8;
}

.cls-3 {
  fill: #fdd4b3;
}

.cls-4 {
  fill: #d5e7b3;
}

.cls-5 {
  fill: #f8c4d4;
}

.cls-6 {
  fill: #d0d2d3;
}

.cls-7 {
  fill: #b3ddca;
}

.cls-8 {
  fill: #e84698;
}

.cls-9 {
  fill: #b1b3b5;
}

.cls-10 {
  fill: #6d6e70;
}

.cls-11 {
  fill: #28745b;
}

.cls-12 {
  fill: #479f6e;
}

.cls-13 {
  fill: #ab662f;
}

.cls-14 {
  fill: #77532b;
}

.cls-15 {
  fill: #00b4d5;
}

.cls-16 {
  fill: #404041;
}

.cls-17 {
  fill: #26244e;
}

.cls-18 {
  fill: #802a83;
}

.cls-19 {
  fill-rule: evenodd;
}

.cls-20,
.cls-21,
.cls-22,
.cls-25,
.cls-32 {
  fill: #231f20;
}

.cls-21 {
  font-size: 9.9px;
}

.cls-21,
.cls-22,
.cls-44,
.cls-45,
.cls-47,
.cls-48 {
  font-family: SVN-HelveticaNeueBold, SVN HelveticaNeueBold;
}

.cls-21,
.cls-22,
.cls-27,
.cls-29,
.cls-32,
.cls-44,
.cls-45,
.cls-46,
.cls-47,
.cls-48 {
  font-weight: 700;
}
 .cls {
   fill: transparent;
 }
.cls-22,
.cls-25,
.cls-32 {
  font-size: 7.91px;
}

.cls-23,
.cls-25,
.cls-30 {
  font-family: UTMHelveBold;
}

.cls-23 {
  font-weight: 400;
}

.cls-24,
.cls-27,
.cls-29,
.cls-32 {
  font-family: UTMHelve-BoldItalic, UTMHelve BoldItalic;
}

.cls-24,
.cls-27,
.cls-29,
.cls-31,
.cls-32,
.cls-46 {
  font-style: italic;
}

.cls-26,
.cls-28 {
  font-family: UTM-Helve, UTM Helve;
}

.cls-28,
.cls-29 {
  letter-spacing: 0em;
}

.cls-30 {
  font-size: 12.24px;
  fill: #b31e8d;
}

.cls-31 {
  font-family: UTMHelve-Italic, UTMHelve Italic;
}

.cls-33 {
  fill: #323031;
}

.cls-34 {
  fill: #c2272d;
}

.cls-35 {
  fill: #d91d52;
}

.cls-36 {
  fill: #f79420;
}

.cls-37 {
  fill: #d71e53;
}

.cls-38 {
  fill: #424143;
}

.cls-39 {
  fill: #f48141;
}

.cls-40 {
  fill: #5d8b53;
}

.cls-41 {
  fill: #745e3e;
}

.cls-42 {
  fill: none;
  stroke: #383535;
  stroke-width: 0.14px;
}

.cls-43 {
  fill: #ea222b;
}

.cls-44 {
  font-size: 10.35px;
}

.cls-44,
.cls-45,
.cls-47,
.cls-48 {
  fill: #221f20;
}

.cls-45 {
  font-size: 8.27px;
}

.cls-46 {
  font-size: 5.88px;
  fill: #ec2027;
  font-family: SVN-HelveticaNeueBoldItalic, SVN HelveticaNeueBoldItalic;
}

.cls-47 {
  font-size: 9.75px;
}

.cls-48 {
  font-size: 7.79px;
}

.cls-49 {
  fill: #ee3742;
} */