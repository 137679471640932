.cls-1,
      .cls-13,
      .cls-15,
      .cls-16,
      .cls-17,
      .cls-18,
      .cls-3,
      .cls-347,
      .cls-373,
      .cls-379,
      .cls-387,
      .cls-391,
      .cls-394,
      .cls-397,
      .cls-4,
      .cls-409 {
        fill: none;
      }

      .cls-2 {
        fill: #efe5de;
      }

      .cls-11,
      .cls-16,
      .cls-17,
      .cls-18,
      .cls-3,
      .cls-4,
      .cls-7 {
        stroke: #939598;
      }

      .cls-10,
      .cls-11,
      .cls-12,
      .cls-15,
      .cls-16,
      .cls-17,
      .cls-18,
      .cls-3,
      .cls-396,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke-linejoin: round;
      }

      .cls-10,
      .cls-11,
      .cls-12,
      .cls-18,
      .cls-3,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke-width: 2px;
      }

      .cls-10,
      .cls-11,
      .cls-12,
      .cls-17,
      .cls-396,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke-linecap: round;
      }

      .cls-5 {
        fill: #5fccf5;
      }

      .cls-10,
      .cls-12,
      .cls-5,
      .cls-6,
      .cls-8,
      .cls-9 {
        stroke: gray;
      }

      .cls-6,
      .cls-7 {
        fill: #0084c8;
      }

      .cls-8 {
        fill: #f7941d;
      }

      .cls-9 {
        fill: #964b36;
      }

      .cls-10 {
        fill: #267db7;
      }

      .cls-11 {
        fill: #8b4832;
      }

      .cls-12 {
        fill: #d4d5d6;
      }

      .cls-13,
      .cls-347 {
        stroke: #231f20;
        stroke-miterlimit: 10;
      }

      .cls-13 {
        stroke-width: 0.3px;
      }

      .cls-14 {
        fill: #ed1c24;
      }

      .cls-15 {
        stroke: #6d6e71;
      }

      .cls-16,
      .cls-18 {
        stroke-linecap: square;
      }

      .cls-16 {
        stroke-width: 4px;
      }

      .cls-115,
      .cls-146,
      .cls-19,
      .cls-20,
      .cls-324,
      .cls-377,
      .cls-380,
      .cls-385,
      .cls-390,
      .cls-396 {
        fill: #fff;
      }

      .cls-146,
      .cls-147,
      .cls-20,
      .cls-21,
      .cls-217,
      .cls-232,
      .cls-239,
      .cls-245,
      .cls-255 {
        font-size: 8px;
      }

      .cls-20,
      .cls-21,
      .cls-217 {
        font-family: HiraKakuPro-W6, HiraKakuPro W6-90ms-RKSJ-H;
      }

      .cls-107,
      .cls-109,
      .cls-119,
      .cls-132,
      .cls-137,
      .cls-147,
      .cls-148,
      .cls-153,
      .cls-170,
      .cls-199,
      .cls-21,
      .cls-22,
      .cls-232,
      .cls-239,
      .cls-245,
      .cls-255,
      .cls-282,
      .cls-284,
      .cls-290,
      .cls-296,
      .cls-300,
      .cls-303,
      .cls-308,
      .cls-325,
      .cls-348,
      .cls-355,
      .cls-357,
      .cls-39,
      .cls-46,
      .cls-47,
      .cls-66,
      .cls-77,
      .cls-94 {
        fill: #231f20;
      }

      .cls-132,
      .cls-137,
      .cls-148,
      .cls-153,
      .cls-170,
      .cls-199,
      .cls-22,
      .cls-282,
      .cls-284,
      .cls-290,
      .cls-300,
      .cls-303,
      .cls-308,
      .cls-324,
      .cls-325,
      .cls-348,
      .cls-355,
      .cls-357,
      .cls-39,
      .cls-411 {
        font-size: 7px;
      }

      .cls-107,
      .cls-109,
      .cls-115,
      .cls-119,
      .cls-132,
      .cls-137,
      .cls-146,
      .cls-147,
      .cls-148,
      .cls-153,
      .cls-170,
      .cls-199,
      .cls-22,
      .cls-232,
      .cls-239,
      .cls-245,
      .cls-255,
      .cls-282,
      .cls-284,
      .cls-290,
      .cls-296,
      .cls-300,
      .cls-303,
      .cls-308,
      .cls-324,
      .cls-325,
      .cls-348,
      .cls-355,
      .cls-357,
      .cls-39,
      .cls-399,
      .cls-400,
      .cls-411,
      .cls-46,
      .cls-47,
      .cls-66,
      .cls-77,
      .cls-94 {
        font-family: HiraKakuPro-W3, HiraKakuPro W3-90ms-RKSJ-H;
      }

      .cls-23 {
        letter-spacing: -0.04em;
      }

      .cls-24 {
        letter-spacing: -0.02em;
      }

      .cls-25 {
        letter-spacing: -0.03em;
      }

      .cls-26 {
        letter-spacing: -0.17em;
      }

      .cls-27 {
        letter-spacing: -0.08em;
      }

      .cls-28 {
        letter-spacing: -0.09em;
      }

      .cls-29 {
        letter-spacing: -0.1em;
      }

      .cls-30,
      .cls-325,

      .cls-31 {
        letter-spacing: -0.05em;
      }

      .cls-32 {
        letter-spacing: -0.15em;
      }

      .cls-33 {
        letter-spacing: -0.08em;
      }

      .cls-34 {
        letter-spacing: -0.12em;
      }

      .cls-35 {
        letter-spacing: -0.1em;
      }

      .cls-36 {
        letter-spacing: -0.01em;
      }

      .cls-37 {
        letter-spacing: -0.15em;
      }

      .cls-38 {
        letter-spacing: -0.07em;
      }

      .cls-39 {
        letter-spacing: -0.14em;
      }

      .cls-40 {
        letter-spacing: -0.17em;
      }

      .cls-41 {
        letter-spacing: -0.24em;
      }

      .cls-42 {
        letter-spacing: -0.14em;
      }

      .cls-43 {
        letter-spacing: -0.18em;
      }

      .cls-44 {
        letter-spacing: -0.17em;
      }

      .cls-45 {
        letter-spacing: -0.05em;
      }

      .cls-107,
      .cls-109,
      .cls-115,
      .cls-119,
      .cls-296,
      .cls-399,
      .cls-400,
      .cls-46,
      .cls-47,
      .cls-66,
      .cls-77,
      .cls-94 {
        font-size: 6px;
      }

      .cls-47 {
        letter-spacing: -0.12em;
      }

      .cls-48 {
        letter-spacing: -0.15em;
      }

      .cls-49 {
        letter-spacing: -0.12em;
      }

      .cls-50 {
        letter-spacing: -0.04em;
      }

      .cls-51 {
        letter-spacing: -0.06em;
      }

      .cls-52 {
        letter-spacing: -0.05em;
      }

      .cls-53 {
        letter-spacing: -0.09em;
      }

      .cls-54 {
        letter-spacing: -0.08em;
      }

      .cls-55 {
        letter-spacing: -0.06em;
      }

      .cls-56 {
        letter-spacing: -0.09em;
      }

      .cls-57 {
        letter-spacing: -0.07em;
      }

      .cls-58 {
        letter-spacing: -0.05em;
      }

      .cls-59 {
        letter-spacing: -0.16em;
      }

      .cls-60 {
        letter-spacing: -0.13em;
      }

      .cls-61 {
        letter-spacing: -0.01em;
      }

      .cls-62 {
        letter-spacing: -0.25em;
      }

      .cls-63 {
        letter-spacing: -0.03em;
      }

      .cls-64 {
        letter-spacing: -0.02em;
      }

      .cls-65 {
        letter-spacing: -0.03em;
      }

      .cls-66 {
        letter-spacing: -0.17em;
      }

      .cls-67 {
        letter-spacing: -0.23em;
      }

      .cls-68 {
        letter-spacing: -0.13em;
      }

      .cls-69 {
        letter-spacing: -0.12em;
      }

      .cls-70 {
        letter-spacing: -0.04em;
      }

      .cls-71 {
        letter-spacing: -0.05em;
      }

      .cls-72 {
        letter-spacing: -0.13em;
      }

      .cls-73 {
        letter-spacing: -0.19em;
      }

      .cls-74 {
        letter-spacing: -0.16em;
      }

      .cls-75 {
        letter-spacing: -0.17em;
      }

      .cls-76 {
        letter-spacing: -0.05em;
      }

      .cls-77 {
        letter-spacing: -0.03em;
      }

      .cls-78 {
        letter-spacing: -0.01em;
      }

      .cls-79 {
        letter-spacing: -0.06em;
      }

      .cls-109,
      .cls-80 {
        letter-spacing: -0.02em;
      }

      .cls-81 {
        letter-spacing: -0.02em;
      }

      .cls-82 {
        letter-spacing: 0em;
      }

      .cls-83 {
        letter-spacing: -0.12em;
      }

      .cls-84 {
        letter-spacing: 0.19em;
      }

      .cls-85 {
        letter-spacing: -0.05em;
      }

      .cls-86 {
        letter-spacing: -0.03em;
      }

      .cls-87 {
        letter-spacing: -0.18em;
      }

      .cls-88 {
        letter-spacing: -0.14em;
      }

      .cls-89 {
        letter-spacing: -0.08em;
      }

      .cls-90 {
        letter-spacing: -0.15em;
      }

      .cls-91 {
        letter-spacing: -0.04em;
      }

      .cls-92 {
        letter-spacing: -0.16em;
      }

      .cls-93 {
        letter-spacing: -0.07em;
      }

      .cls-94 {
        letter-spacing: -0.15em;
      }

      .cls-95 {
        letter-spacing: -0.17em;
      }

      .cls-96 {
        letter-spacing: -0.13em;
      }

      .cls-97 {
        letter-spacing: -0.04em;
      }

      .cls-98 {
        letter-spacing: -0.04em;
      }

      .cls-99 {
        letter-spacing: -0.05em;
      }

      .cls-100 {
        letter-spacing: -0.15em;
      }

      .cls-101 {
        letter-spacing: -0.08em;
      }

      .cls-102 {
        letter-spacing: -0.08em;
      }

      .cls-103 {
        letter-spacing: -0.1em;
      }

      .cls-104 {
        letter-spacing: -0.07em;
      }

      .cls-105 {
        letter-spacing: -0.14em;
      }

      .cls-106 {
        letter-spacing: -0.01em;
      }

      .cls-107 {
        letter-spacing: -0.08em;
      }

      .cls-108 {
        letter-spacing: -0.02em;
      }

      .cls-110 {
        letter-spacing: -0.07em;
      }

      .cls-111 {
        letter-spacing: -0.02em;
      }

      .cls-112 {
        letter-spacing: -0.16em;
      }

      .cls-113 {
        letter-spacing: -0.11em;
      }

      .cls-114 {
        letter-spacing: -0.04em;
      }

      .cls-116 {
        letter-spacing: -0.15em;
      }

      .cls-117 {
        letter-spacing: -0.18em;
      }

      .cls-118 {
        letter-spacing: -0.16em;
      }

      .cls-119,
      .cls-129 {
        letter-spacing: -0.07em;
      }

      .cls-120 {
        letter-spacing: -0.08em;
      }

      .cls-121 {
        letter-spacing: -0.11em;
      }

      .cls-122 {
        letter-spacing: -0.15em;
      }

      .cls-123 {
        letter-spacing: -0.09em;
      }

      .cls-124 {
        letter-spacing: -0.23em;
      }

      .cls-125 {
        letter-spacing: -0.01em;
      }

      .cls-126 {
        letter-spacing: -0.02em;
      }

      .cls-127 {
        letter-spacing: -0.06em;
      }

      .cls-128 {
        letter-spacing: -0.05em;
      }

      .cls-130 {
        letter-spacing: -0.09em;
      }

      .cls-131 {
        letter-spacing: -0.01em;
      }

      .cls-132 {
        letter-spacing: -0.15em;
      }

      .cls-133 {
        letter-spacing: -0.13em;
      }

      .cls-134 {
        letter-spacing: -0.14em;
      }

      .cls-135 {
        letter-spacing: -0.16em;
      }

      .cls-136 {
        letter-spacing: -0.04em;
      }

      .cls-137 {
        letter-spacing: 0.06em;
      }

      .cls-138 {
        letter-spacing: -0.03em;
      }

      .cls-139 {
        letter-spacing: 0.01em;
      }

      .cls-140 {
        letter-spacing: 0.09em;
      }

      .cls-141 {
        letter-spacing: 0.11em;
      }

      .cls-142 {
        letter-spacing: -0.13em;
      }

      .cls-143 {
        letter-spacing: 0.05em;
      }

      .cls-144 {
        letter-spacing: 0.08em;
      }

      .cls-145 {
        letter-spacing: 0.12em;
      }

      .cls-148 {
        letter-spacing: -0.15em;
      }

      .cls-149 {
        letter-spacing: -0.31em;
      }

      .cls-150 {
        letter-spacing: -0.04em;
      }

      .cls-151 {
        letter-spacing: 0.25em;
      }

      .cls-152 {
        letter-spacing: 0.25em;
      }

      .cls-153 {
        letter-spacing: -0.11em;
      }

      .cls-154 {
        letter-spacing: -0.18em;
      }

      .cls-155 {
        letter-spacing: -0.12em;
      }

      .cls-156 {
        letter-spacing: -0.14em;
      }

      .cls-157 {
        letter-spacing: -0.09em;
      }

      .cls-158 {
        letter-spacing: -0.17em;
      }

      .cls-159 {
        letter-spacing: -0.25em;
      }

      .cls-160 {
        letter-spacing: -0.09em;
      }

      .cls-161 {
        letter-spacing: -0.25em;
      }

      .cls-162 {
        letter-spacing: -0.27em;
      }

      .cls-163 {
        letter-spacing: -0.31em;
      }

      .cls-164 {
        letter-spacing: -0.04em;
      }

      .cls-165 {
        letter-spacing: -0.15em;
      }

      .cls-166 {
        letter-spacing: -0.08em;
      }

      .cls-167 {
        letter-spacing: -0.15em;
      }

      .cls-168 {
        letter-spacing: -0.2em;
      }

      .cls-169 {
        letter-spacing: -0.06em;
      }

      .cls-170 {
        letter-spacing: -0.12em;
      }

      .cls-171 {
        letter-spacing: -0.18em;
      }

      .cls-172 {
        letter-spacing: -0.17em;
      }

      .cls-173 {
        letter-spacing: -0.04em;
      }

      .cls-174 {
        letter-spacing: -0.09em;
      }

      .cls-175 {
        letter-spacing: -0.27em;
      }

      .cls-176 {
        letter-spacing: -0.06em;
      }

      .cls-177 {
        letter-spacing: -0.18em;
      }

      .cls-178 {
        letter-spacing: -0.19em;
      }

      .cls-179 {
        letter-spacing: -0.08em;
      }

      .cls-180 {
        letter-spacing: -0.3em;
      }

      .cls-181 {
        letter-spacing: -0.25em;
      }

      .cls-182 {
        letter-spacing: -0.04em;
      }

      .cls-183 {
        letter-spacing: -0.2em;
      }

      .cls-184 {
        letter-spacing: -0.09em;
      }

      .cls-185 {
        letter-spacing: -0.29em;
      }

      .cls-186 {
        letter-spacing: -0.2em;
      }

      .cls-187 {
        letter-spacing: -0.04em;
      }

      .cls-188 {
        letter-spacing: -0.03em;
      }

      .cls-189 {
        letter-spacing: -0.13em;
      }

      .cls-190 {
        letter-spacing: -0.15em;
      }

      .cls-191 {
        letter-spacing: -0.13em;
      }

      .cls-192 {
        letter-spacing: -0.14em;
      }

      .cls-193 {
        letter-spacing: -0.16em;
      }

      .cls-194 {
        letter-spacing: -0.17em;
      }

      .cls-195 {
        letter-spacing: -0.17em;
      }

      .cls-196 {
        letter-spacing: -0.25em;
      }

      .cls-197 {
        letter-spacing: -0.27em;
      }

      .cls-198 {
        letter-spacing: -0.05em;
      }

      .cls-199 {
        letter-spacing: -0.1em;
      }

      .cls-200 {
        letter-spacing: -0.16em;
      }

      .cls-201 {
        letter-spacing: -0.2em;
      }

      .cls-202 {
        letter-spacing: -0.17em;
      }

      .cls-203 {
        letter-spacing: -0.25em;
      }

      .cls-204 {
        letter-spacing: -0.1em;
      }

      .cls-205 {
        letter-spacing: -0.19em;
      }

      .cls-206 {
        letter-spacing: -0.23em;
      }

      .cls-207 {
        letter-spacing: -0.05em;
      }

      .cls-208 {
        letter-spacing: -0.03em;
      }

      .cls-209 {
        letter-spacing: -0.12em;
      }

      .cls-210 {
        letter-spacing: -0.15em;
      }

      .cls-211 {
        letter-spacing: -0.15em;
      }

      .cls-212 {
        letter-spacing: -0.23em;
      }

      .cls-213 {
        letter-spacing: -0.23em;
      }

      .cls-214 {
        letter-spacing: -0.06em;
      }

      .cls-215 {
        letter-spacing: -0.14em;
      }

      .cls-216 {
        letter-spacing: -0.03em;
      }

      .cls-218 {
        letter-spacing: -0.06em;
      }

      .cls-219 {
        letter-spacing: -0.21em;
      }

      .cls-220 {
        letter-spacing: -0.15em;
      }

      .cls-221 {
        letter-spacing: -0.14em;
      }

      .cls-222 {
        letter-spacing: -0.11em;
      }

      .cls-223 {
        letter-spacing: -0.01em;
      }

      .cls-224 {
        letter-spacing: -0.03em;
      }

      .cls-225 {
        letter-spacing: -0.11em;
      }

      .cls-226 {
        letter-spacing: -0.14em;
      }

      .cls-227 {
        letter-spacing: -0.04em;
      }

      .cls-228 {
        letter-spacing: -0.18em;
      }

      .cls-229 {
        letter-spacing: -0.18em;
      }

      .cls-230 {
        letter-spacing: -0.15em;
      }

      .cls-231 {
        letter-spacing: -0.03em;
      }

      .cls-232 {
        letter-spacing: 0em;
      }

      .cls-233 {
        letter-spacing: 0em;
      }

      .cls-234 {
        letter-spacing: -0.01em;
      }

      .cls-235 {
        letter-spacing: -0.01em;
      }

      .cls-236 {
        letter-spacing: -0.01em;
      }

      .cls-237 {
        letter-spacing: 0.05em;
      }

      .cls-238 {
        letter-spacing: -0.02em;
      }

      .cls-239 {
        letter-spacing: -0.14em;
      }

      .cls-240 {
        letter-spacing: -0.17em;
      }

      .cls-241 {
        letter-spacing: -0.14em;
      }

      .cls-242 {
        letter-spacing: -0.07em;
      }

      .cls-243 {
        letter-spacing: -0.04em;
      }

      .cls-244 {
        letter-spacing: -0.02em;
      }

      .cls-245 {
        letter-spacing: -0.09em;
      }

      .cls-246 {
        letter-spacing: -0.13em;
      }

      .cls-247 {
        letter-spacing: -0.14em;
      }

      .cls-248 {
        letter-spacing: -0.02em;
      }

      .cls-249 {
        letter-spacing: -0.02em;
      }

      .cls-250 {
        letter-spacing: -0.02em;
      }

      .cls-251 {
        letter-spacing: -0.25em;
      }

      .cls-252 {
        letter-spacing: -0.1em;
      }

      .cls-253 {
        letter-spacing: -0.22em;
      }

      .cls-254 {
        letter-spacing: -0.04em;
      }

      .cls-255 {
        letter-spacing: -0.06em;
      }

      .cls-256 {
        letter-spacing: -0.24em;
      }

      .cls-257 {
        letter-spacing: -0.08em;
      }

      .cls-258 {
        letter-spacing: -0.11em;
      }

      .cls-259 {
        letter-spacing: -0.28em;
      }

      .cls-260 {
        letter-spacing: -0.03em;
      }

      .cls-261 {
        letter-spacing: -0.07em;
      }

      .cls-262 {
        letter-spacing: -0.16em;
      }

      .cls-263 {
        letter-spacing: -0.2em;
      }

      .cls-264 {
        letter-spacing: -0.06em;
      }

      .cls-265 {
        letter-spacing: -0.04em;
      }

      .cls-266 {
        letter-spacing: -0.02em;
      }

      .cls-267 {
        letter-spacing: -0.12em;
      }

      .cls-268 {
        letter-spacing: -0.13em;
      }

      .cls-269 {
        letter-spacing: -0.1em;
      }

      .cls-270 {
        letter-spacing: -0.08em;
      }

      .cls-271 {
        letter-spacing: -0.17em;
      }

      .cls-272 {
        letter-spacing: -0.02em;
      }

      .cls-273 {
        letter-spacing: -0.05em;
      }

      .cls-274 {
        letter-spacing: -0.06em;
      }

      .cls-275 {
        letter-spacing: -0.05em;
      }

      .cls-276 {
        letter-spacing: -0.04em;
      }

      .cls-277 {
        letter-spacing: 0.25em;
      }

      .cls-278 {
        letter-spacing: -0.01em;
      }

      .cls-279 {
        letter-spacing: -0.03em;
      }

      .cls-280 {
        letter-spacing: -0.06em;
      }

      .cls-281 {
        letter-spacing: -0.02em;
      }

      .cls-282 {
        letter-spacing: -0.06em;
      }

      .cls-283 {
        letter-spacing: -0.03em;
      }

      .cls-284 {
        letter-spacing: -0.15em;
      }

      .cls-285 {
        letter-spacing: -0.13em;
      }

      .cls-286 {
        letter-spacing: -0.06em;
      }

      .cls-287 {
        letter-spacing: -0.15em;
      }

      .cls-288 {
        letter-spacing: -0.07em;
      }

      .cls-289 {
        letter-spacing: -0.03em;
      }

      .cls-290,
      .cls-328 {
        letter-spacing: -0.08em;
      }

      .cls-291 {
        letter-spacing: -0.04em;
      }

      .cls-292 {
        letter-spacing: -0.09em;
      }

      .cls-293 {
        letter-spacing: -0.05em;
      }

      .cls-294 {
        letter-spacing: -0.09em;
      }

      .cls-295 {
        letter-spacing: -0.07em;
      }

      .cls-296,
      .cls-298 {
        letter-spacing: -0.06em;
      }

      .cls-297 {
        letter-spacing: -0.1em;
      }

      .cls-299 {
        letter-spacing: -0.02em;
      }

      .cls-300,
      .cls-307 {
        letter-spacing: -0.09em;
      }

      .cls-301,
      .cls-355 {
        letter-spacing: -0.06em;
      }

      .cls-302 {
        letter-spacing: -0.02em;
      }

      .cls-303,
      .cls-356 {
        letter-spacing: -0.03em;
      }

      .cls-304,
      .cls-308 {
        letter-spacing: -0.02em;
      }

      .cls-305 {
        letter-spacing: -0.01em;
      }

      .cls-306 {
        letter-spacing: -0.07em;
      }

      .cls-309 {
        letter-spacing: -0.07em;
      }

      .cls-310 {
        letter-spacing: -0.14em;
      }

      .cls-311 {
        letter-spacing: -0.15em;
      }

      .cls-312 {
        letter-spacing: -0.16em;
      }

      .cls-313 {
        letter-spacing: -0.13em;
      }

      .cls-314 {
        letter-spacing: -0.05em;
      }

      .cls-315 {
        letter-spacing: -0.11em;
      }

      .cls-316 {
        letter-spacing: -0.16em;
      }

      .cls-317 {
        letter-spacing: -0.13em;
      }

      .cls-318 {
        letter-spacing: -0.05em;
      }

      .cls-319 {
        letter-spacing: -0.18em;
      }

      .cls-320 {
        letter-spacing: -0.17em;
      }

      .cls-321 {
        letter-spacing: -0.18em;
      }

      .cls-322 {
        letter-spacing: -0.13em;
      }

      .cls-323 {
        letter-spacing: -0.04em;
      }

      .cls-326 {
        letter-spacing: -0.12em;
      }

      .cls-327 {
        letter-spacing: -0.17em;
      }

      .cls-329 {
        letter-spacing: -0.13em;
      }

      .cls-330 {
        letter-spacing: -0.02em;
      }

      .cls-331 {
        letter-spacing: -0.04em;
      }

      .cls-332 {
        letter-spacing: -0.06em;
      }

      .cls-333 {
        letter-spacing: -0.18em;
      }

      .cls-334 {
        letter-spacing: -0.13em;
      }

      .cls-335 {
        letter-spacing: -0.1em;
      }

      .cls-336 {
        letter-spacing: -0.16em;
      }

      .cls-337 {
        letter-spacing: -0.12em;
      }

      .cls-338 {
        letter-spacing: -0.11em;
      }

      .cls-339 {
        letter-spacing: -0.05em;
      }

      .cls-340 {
        letter-spacing: -0.13em;
      }

      .cls-341 {
        letter-spacing: -0.1em;
      }

      .cls-342 {
        letter-spacing: -0.07em;
      }

      .cls-343 {
        letter-spacing: -0.11em;
      }

      .cls-344 {
        letter-spacing: -0.03em;
      }

      .cls-345 {
        letter-spacing: -0.01em;
      }

      .cls-346 {
        letter-spacing: -0.06em;
      }

      .cls-347 {
        stroke-width: 0.25px;
      }

      .cls-348 {
        letter-spacing: -0.02em;
      }

      .cls-349 {
        letter-spacing: -0.1em;
      }

      .cls-350 {
        letter-spacing: -0.16em;
      }

      .cls-351 {
        letter-spacing: -0.08em;
      }

      .cls-352 {
        letter-spacing: -0.06em;
      }

      .cls-353 {
        letter-spacing: -0.15em;
      }

      .cls-354 {
        letter-spacing: -0.07em;
      }

      .cls-357 {
        letter-spacing: -0.16em;
      }

      .cls-358 {
        letter-spacing: -0.14em;
      }

      .cls-359 {
        letter-spacing: -0.13em;
      }

      .cls-360 {
        letter-spacing: -0.18em;
      }

      .cls-361 {
        letter-spacing: -0.05em;
      }

      .cls-362 {
        letter-spacing: -0.07em;
      }

      .cls-363 {
        letter-spacing: -0.1em;
      }

      .cls-364 {
        letter-spacing: -0.29em;
      }

      .cls-365 {
        letter-spacing: -0.15em;
      }

      .cls-366 {
        letter-spacing: -0.17em;
      }

      .cls-367 {
        letter-spacing: -0.05em;
      }

      .cls-368 {
        clip-path: url(#clip-path);
      }

      .cls-369 {
        fill: #a3a3a3;
      }

      .cls-371 {
        fill: #010101;
      }

      .cls-372,
      .cls-392 {
        fill: #1c1c1c;
      }

      .cls-373,
      .cls-379,
      .cls-387,
      .cls-391,
      .cls-394,
      .cls-396,
      .cls-397,
      .cls-409 {
        stroke: #fff;
      }

      .cls-373 {
        stroke-width: 0.88px;
      }

      .cls-374 {
        fill: #d0d0d0;
      }

      .cls-375 {
        clip-path: url(#clip-path-2);
      }

      .cls-376,
      .cls-377,
      .cls-383,
      .cls-392,
      .cls-393 {
        fill-rule: evenodd;
      }

      .cls-378 {
        fill: #5c5c5c;
      }

      .cls-379 {
        stroke-width: 1px;
      }

      .cls-380 {
        stroke: #1c1c1c;
        stroke-width: 0.57px;
      }

      .cls-381 {
        fill: #35b558;
      }

      .cls-382 {
        fill: gray;
      }

      .cls-383 {
        fill: #44c8f5;
      }

      .cls-384 {
        fill: #333;
      }

      .cls-385,
      .cls-390 {
        stroke: #000;
      }

      .cls-385 {
        stroke-width: 0.32px;
      }

      .cls-386 {
        fill: #00b395;
      }

      .cls-387 {
        stroke-width: 0.91px;
      }

      .cls-388 {
        clip-path: url(#clip-path-4);
      }

      .cls-390 {
        stroke-width: 0.61px;
      }

      .cls-391 {
        stroke-width: 0.81px;
      }

      .cls-393 {
        fill: #545454;
      }

      .cls-394 {
        stroke-width: 0.92px;
      }

      .cls-395 {
        clip-path: url(#clip-path-6);
      }

      .cls-396 {
        stroke-width: 0.12px;
      }

      .cls-397 {
        stroke-width: 1.02px;
      }

      .cls-398,
      .cls-399,
      .cls-410,
      .cls-411 {
        fill: #4d4d4d;
      }

      .cls-402 {
        letter-spacing: -0.04em;
      }

      .cls-403 {
        letter-spacing: -0.17em;
      }

      .cls-405 {
        letter-spacing: -0.5em;
      }

      .cls-406 {
        letter-spacing: -0.08em;
      }

      .cls-407 {
        letter-spacing: -0.02em;
      }

      .cls-408 {
        fill: #999;
      }

      .cls-409 {
        stroke-width: 0.94px;
      }

      .cls-410 {
        stroke: #4d4d4d;
        stroke-width: 0.25px;
      }